import { asArray } from '@ng-doc/core/helpers/as-array';
import { objectKeys } from '@ng-doc/core/helpers/object-keys';

/**
 *
 * @param value
 */
function asBoolean(value) {
  if (typeof value === 'string') {
    return value.toLowerCase() === 'true' ? true : value.toLowerCase() === 'false' ? false : !!value;
  }
  return !!value;
}

/**
 *
 * @param viewContainer
 * @param component
 * @param inputs
 */
function createComponent(viewContainer, component, inputs) {
  viewContainer.clear();
  const componentRef = viewContainer.createComponent(component);
  if (inputs) {
    Object.entries(inputs).forEach(([key, value]) => {
      componentRef.setInput(key, value);
    });
  }
}

/**
 *
 * @param html
 */
async function formatHtml(html) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const formatter = await import('esthetic');
  return formatter.default.html(html.trim(), {
    wrap: 50,
    markup: {
      forceIndent: true
    }
  });
}

/**
 * Generate table of contents, only for headings with id
 * @param container
 * @param headings
 */
function generateToc(container) {
  const headings = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
  const headingElements = Array.from(container.querySelectorAll(headings.join(', '))).filter(heading => heading.id);
  const levels = asArray(new Set(headingElements.map(levelFromTagName).sort()));
  return headingElements.reduce((map, heading) => {
    const headingLevel = levelFromTagName(heading);
    const anchor = heading.querySelector('a.ng-doc-header-link');
    if (anchor) {
      map.push({
        title: heading.textContent?.trim() ?? '',
        element: heading,
        path: anchor.pathname,
        hash: anchor.hash.replace('#', ''),
        level: levels.indexOf(headingLevel) + 1
      });
    }
    return map;
  }, []);
}
/**
 *
 * @param heading
 */
function levelFromTagName(heading) {
  return Number(heading.tagName.toLowerCase().replace(/[a-z]*/g, '') || 1);
}

/**
 * Check if the OS theme is dark
 */
function isDarkOsTheme() {
  return window.matchMedia('(prefers-color-scheme: dark)').matches;
}

/**
 *
 * @param url
 */
function isExternalLink(url) {
  const anchorElement = document.createElement('a');
  anchorElement.href = url;
  return anchorElement.host !== window.location.host;
}

/**
 *
 * @param obj
 */
function isPlaygroundProperty(obj) {
  return objectKeys(obj).includes('type');
}

/**
 * Generated bundle index. Do not edit.
 */

export { asBoolean, createComponent, formatHtml, generateToc, isDarkOsTheme, isExternalLink, isPlaygroundProperty };
