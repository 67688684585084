import { InjectionToken } from '@angular/core';
const tokenStore = new Map();
/**
 *
 * @param type
 * @param control
 * @param options
 */
function provideTypeControl(type, control, options) {
  const token = new InjectionToken(`NG_DOC_TYPE_CONTROL_${type}`, {
    providedIn: 'root',
    factory: () => {
      return {
        control,
        options
      };
    }
  });
  tokenStore.set(type, token);
  return {
    provide: 'nothing',
    useValue: null
  };
}
/**
 * Returns token for type control based on provided type
 * @param type - type for searched control (e.g. `string`)
 */
function getTokenForType(type) {
  return tokenStore.get(type);
}

/**
 * Generated bundle index. Do not edit.
 */

export { getTokenForType, provideTypeControl };
