/**
 * Base class for NgDoc page
 */
class NgDocRootPage {}

/**
 * Generated bundle index. Do not edit.
 */

export { NgDocRootPage };
